$(document).on('turbolinks:load', function() {
  $('.loader-green-btn').on('click', function() {
    $(this).find('.button-text').css('display', 'none'); // ボタンのテキストを非表示にする
    $(this).find('.button-icon').css('display', 'none'); // ボタンのアイコンを非表示にする
    $(this).find('.loader-green').removeClass('d-none'); // ローディングアイコンを表示する
  });
  $('.loader-orange-btn').on('click', function() {
    $(this).find('.button-text').css('display', 'none'); // ボタンのテキストを非表示にする
    $(this).find('.button-icon').css('display', 'none'); // ボタンのアイコンを非表示にする
    $(this).find('.loader-orange').removeClass('d-none'); // ローディングアイコンを表示する
  });
});

// ================================
// ローディングアイコンの表示制御
// ================================
document.addEventListener('DOMContentLoaded', function() {
  let loadingSpinnerTimeout;
  const spinner = document.getElementById('loading-spinner');

  // Turbolinksを使用している場合
  if (window.Turbolinks) {
    document.addEventListener('turbolinks:request-start', function() {
      loadingSpinnerTimeout = setTimeout(function() {
        spinner.style.display = 'flex'; // displayをflexに設定
        setTimeout(function() {
          spinner.classList.add('active'); // activeクラスを追加して表示
        }, 10); // 少し遅らせてopacityを変更
      }, 500); // 0.5秒後にローディングスピナーを表示
    });

    document.addEventListener('turbolinks:request-end', function() {
      clearTimeout(loadingSpinnerTimeout);
      spinner.classList.remove('active');
      setTimeout(function() {
        spinner.style.display = 'none';
      }, 300); // opacityが変わる時間と合わせる
    });

    document.addEventListener('turbolinks:load', function() {
      clearTimeout(loadingSpinnerTimeout);
      spinner.classList.remove('active');
      setTimeout(function() {
        spinner.style.display = 'none';
      }, 300); // opacityが変わる時間と合わせる
    });
  } else {
    // Turbolinksを使用していない場合
    window.addEventListener('beforeunload', function() {
      loadingSpinnerTimeout = setTimeout(function() {
        spinner.style.display = 'flex'; // displayをflexに設定
        setTimeout(function() {
          spinner.classList.add('active'); // activeクラスを追加して表示
        }, 10); // 少し遅らせてopacityを変更
      }, 500); // 0.5秒後にローディングスピナーを表示
    });

    window.addEventListener('load', function() {
      clearTimeout(loadingSpinnerTimeout);
      spinner.classList.remove('active');
      setTimeout(function() {
        spinner.style.display = 'none';
      }, 300); // opacityが変わる時間と合わせる
    });
  }
});