// jQueryのカスタムメソッドとしてtoggleTextを定義
jQuery.fn.toggleText = function(text1, text2) {
  return this.each(function() {
    var $this = $(this);
    if ($this.text() === text1) $this.text(text2);
    else $this.text(text1);
  });
};

// トグルボタンの実装
$(document).on('turbolinks:load', function () {
  // FAQの質問をクリックした時の動作
  $('.faq-question').on('click', function() {
    $(this).next('.faq-answer').slideToggle(200);
    $(this).children('.fa').toggleClass('fa-chevron-down fa-chevron-up');
  });

  // アカウント編集画面の変更後パスワードフォームのトグル操作
  $('#password-change-form').hide(); // 初期状態では非表示
  $('#password-change-toggle').on('click', function() {
    $(this).next('#password-change-form').slideToggle(200);
    $(this).children('.fa').toggleClass('fa-chevron-down fa-chevron-right');
  });

  // リーグ詳細画面のタイトルトグル
  $('.league-title').on('click', function() {
    $(this).children('.toggle-hidden').slideToggle(200);
    $(this).children('.league-title-value').children('.fa').toggleClass('fa-chevron-down fa-chevron-up');
  });
  // 成績登録フォームのptで記録する留意事項のトグル
  $('#info-pt-record-content').hide(); // 初期状態では非表示
  $('#info-pt-record').on('click', function() {
    $(this).children('ul').slideToggle(300);
    $(this).children('.info-title').children('.fa').toggleClass('fa-chevron-right fa-chevron-down');
  });

  // ウィンドウ幅が767px以下(tablet以下)の場合のみ実行
  if (window.matchMedia("(max-width: 767px)").matches) {
    // TOPページの主な機能のトグル
    $('.feature-card-sample').hide(); // 初期状態では非表示
    $('.feature-card').on('click', function() {
      $(this).children('.feature-card-contents').children('.feature-card-sample').slideToggle(300);
      $(this).children('.feature-card-toggle').children('i').toggleClass('fa-circle-chevron-down fa-circle-chevron-up');
      $(this).children('.feature-card-toggle').children('span').toggleText('詳しくみる', '閉じる');
    });
  }
});
