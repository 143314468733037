function redirectTo(url, playType) {
  const newUrl = url + "?play_type=" + playType;
  location.href = newUrl;
}

$(document).on('click', '#toggle-to-three-m', function() {
  redirectTo("/matches", 3);
});
$(document).on('click', '#toggle-to-four-m', function() {
  redirectTo("/matches", 4);
});
$(document).on('click', '#toggle-to-three-mg', function() {
  redirectTo("/match_groups", 3);
});
$(document).on('click', '#toggle-to-four-mg', function() {
  redirectTo("/match_groups", 4);
});