$(document).on('turbolinks:load', function () {
  const toggleToChild = document.getElementById('toggle-to-child');
  const toggleToParent = document.getElementById('toggle-to-parent');
  const pointTableChild = document.getElementById('point-table-child');
  const pointTableParent = document.getElementById('point-table-parent');

  if (toggleToChild && toggleToParent && pointTableChild && pointTableParent) {
    toggleToChild.addEventListener('click', function() {
      toggleToChild.classList.add('active');
      toggleToParent.classList.remove('active');
      pointTableChild.style.display = 'table';
      pointTableParent.style.display = 'none';
    });

    toggleToParent.addEventListener('click', function() {
      toggleToParent.classList.add('active');
      toggleToChild.classList.remove('active');
      pointTableParent.style.display = 'table';
      pointTableChild.style.display = 'none';
    });

    // 初期表示設定
    pointTableChild.style.display = 'table';
    pointTableParent.style.display = 'none';
  }
});