// プレイヤー選択における選択したプレイヤーの追加を行う
$(document).on('turbolinks:load', function () {

  if (window.location.pathname.includes('/players/new')) {

    let leaguePlayerEdit = gon.leaguePlayerEdit;
    if (leaguePlayerEdit) {
      selectedPlayers = [];
    } else {
      let selectedPlayers_str = sessionStorage.getItem('selected_players'); // jsセッション情報取得
      selectedPlayers = JSON.parse(selectedPlayers_str); // 文字列→配列変換
    }

    if (selectedPlayers === "[]" || selectedPlayers === null) {
      selectedPlayers = [];
    } else {
      // jsセッション情報があればプレイヤー選択している状態にする
      var $selectedPlayersContainer = $('.selected_players');

      let select_players_items_p_id = []; // 選択済みプレイヤーのp_idを格納する配列
      // 選択済みプレイヤーのp_idを取得
      $(".selected_players-item").each(function() {
        select_players_items_p_id.push(Number($(this).find(".p_id").text()));
      });

      // selectedPlayersに格納されているplayer情報が
      //.selected_players-itemに存在しない場合のみ、DOMを追加する
      selectedPlayers.forEach(function(player) {

        if (select_players_items_p_id.includes(player[0])) {
          return;
        }

        var $playerItem = $(
          `<div class="selected_players-item">
              <span>${player[1]}</span>
              <i class='bx bx-trash player-delete-2'></i>
              <div class="p_id" style="display: none">${player[0]}</div>
            </div>`
        );
        $selectedPlayersContainer.append($playerItem);

      });
    }

    // selectedPlayers を現在の DOM から再構築する際に、重複を削除する
    selectedPlayers = []; // 配列をリセット

    let p_idSet = new Set(); // p_id を格納するセットを作成

    $(".selected_players-item").each(function() {
      let p_id = Number($(this).find(".p_id").text());
      let p_name = $(this).find("span").text();

      // p_id がセットに含まれていない場合のみ追加
      if (!p_idSet.has(p_id)) {
        selectedPlayers.push([p_id, p_name]);
        p_idSet.add(p_id); // セットに p_id を追加
      }

    });

    // 選択済みプレイヤーの背景色をグレーに変更
    let selected_p_ids = selectedPlayers.map(selectedPlayer => selectedPlayer[0]);
    $(".players_list-item").each(function() {
      let p_id = Number($(this).find(".p_id").text()); // p_idを数値に変換
      if (selected_p_ids.includes(p_id)) {
        $(this).css("background-color", "#c5c5c5");
      }
    });
  
    // プレイヤー選択数の上限を設定
    let playerCount = $('.player-num').text();
    let maxPlayers = Number(playerCount);

    let minPlayers = getUrlParameterPlayType(); // プレイヤー選択数の下限を設定
  
    update_selected_counter(); // 選択済みプレイヤー数を更新
  
    update_params(); // セッション格納分のプレイヤー情報をparamsに追加
    updateCreateButtonActivation(); // 決定ボタン状態の初期設定をする
  
    // ==================== クリックイベント処理 ==================== //
    // ⚫︎プレイヤーを選択肢から選択する処理
    $('.players_list-item').on('click', function () {
      let p_name = $(this).find(".p_name").text();
      let p_id = $(this).find(".p_id").text();

      // 選択プレイヤー上限数をチェック
      if ($(".selected_players-item").length >= maxPlayers) {
        // 選択制限に達した場合、エラーメッセージを表示
        alert('上限に達しているため追加できません');
        return;
      }
      // 選択した.players_list-itemの背景色をグレーに変更
      $(this).css("background-color", "#c5c5c5");
      add_players(p_name, p_id);
      update_params();
      update_selected_counter();
      updateCreateButtonActivation();
  
    });
  
    // ⚫︎プレイヤー新規作成処理
    $('.create_players button').on('click', function () {
      if ($(".selected_players-item").length >= maxPlayers) {
        // 人数制限に達した場合、エラーメッセージを表示
        alert('上限に達しているため追加できません');
        return;
      }
      let newPlayerName = $('.create_players input').val().trim(); // 前後の空白を削除
      if (newPlayerName === "") {
        // 入力が空白の場合はエラーメッセージを表示するか、処理を中断できます
        alert('プレイヤー名を入力してください。');
        return;
      }
      add_players(newPlayerName, 0);
      update_selected_counter();
      update_params();
      updateCreateButtonActivation();
      $('.create_players input').val(''); // inputを空にする
    });
  
    // ⚫︎プレイヤー選択解除処理① - 追加したアカウント登録済みプレイヤーを削除する場合
    //  → プレイヤー情報はコントローラーで取得しているためセッションは更新しない
    $('.selected_players').on('click', '.player-delete-1', function () {
      let delete_p_id = Number($(this).siblings('.p_id').text()); // クリックされたプレイヤーのidを取得
      // クリックされたプレイヤーをselectedPlayersから削除
      selectedPlayers = selectedPlayers.filter(selectedPlayer => selectedPlayer[0] !== delete_p_id);

      $(this).parent().remove();
      update_selected_counter(); // 選択済みプレイヤー数更新
      update_params(); // プレイヤー決定時に送信されるparamsを更新
      updateCreateButtonActivation(); // 決定ボタン更新
    });
  
    // ⚫︎プレイヤー選択解除処理２- 過去遊んだプレイヤーor新規登録プレイヤーを削除する場合
    //  → プレイヤー情報をセッションに格納しているため,セッションは更新する
    $('.selected_players').on('click', '.player-delete-2', function () {
      let delete_p_id = Number($(this).siblings('.p_id').text()); // クリックされたプレイヤーのidを取得
      // クリックされたプレイヤーをselectedPlayersから削除
      selectedPlayers = selectedPlayers.filter(selectedPlayer => selectedPlayer[0] !== delete_p_id);
      sessionStorage['selected_players'] = JSON.stringify(selectedPlayers); //セッション更新
  
      // 対応する.players_list-itemの背景色を元に戻す
      let p_id = $(this).siblings('.p_id').text();
      $(".players_list-item").each(function() {
        if ($(this).find(".p_id").text() == p_id) {
          $(this).css("background-color", "");
        }
      });
      $(this).parent().remove();
      update_selected_counter(); // 選択済みプレイヤー数更新
      update_params(); // プレイヤー決定時に送信されるparamsを更新
      updateCreateButtonActivation(); // 決定ボタン更新
    });
  
    // ⚫︎プレイヤー決定したらセッションをクリアする'
    $('#create_btn').on('click', function () {
      sessionStorage.clear();
    });
  
    //  ******** 関数 **************** //
  
    function add_players(selected_player_name, p_id){
      let id = Number(p_id);
  
      let ids = selectedPlayers.map(selectedPlayer => selectedPlayer[0]);
      // 選択済みのプレイヤーをチェック
      if (($.inArray(id, ids) !== -1) && (id !== 0)) {
        // // すでに選択済みの場合、何もしない
      } else {
        // 未選択の場合、選択プレイヤーに追加
        selectedPlayers.push([id, selected_player_name]);
        sessionStorage['selected_players'] = JSON.stringify(selectedPlayers); //セッション更新
        $('.selected_players').append(
          `<div class="selected_players-item">
            <span>${selected_player_name}</span>
            <i class='bx bx-trash player-delete-2'></i>
            <div class="p_id" style="display: none">${p_id}</div>
          </div>`
        );
      }
    }
    
    // プレイヤー決定時に送信されるparamsを更新する
    function update_params() {
      
      // 最初に#create_btn内のすべてのinput要素を削除
      $('#create_btn').find('input').remove();
  
      // プレイヤー人数になった場合
      // リーグプレイヤー編集モードかつプレイヤー人数が上限以下の場合
      // #create_btnにparamsを追加
      if ($('.selected_players-item').length === maxPlayers || (leaguePlayerEdit && $('.selected_players-item').length <= maxPlayers)) {
  
        let selectedPlayerNames = [];
        let selectedPlayerIds = [];
        
        // "selected_players-item" クラスを持つすべての要素をループ
        $(".selected_players-item").each(function() {
            let playerName = $(this).find("span").text();
            let playerId = $(this).find(".p_id").text();
            selectedPlayerNames.push(playerName);
            selectedPlayerIds.push(playerId);
        });
        for (var i = 0; i < selectedPlayerIds.length; i++) {
          $('#create_btn').append(
            `<input type="hidden" name="p_ids[]" value="${selectedPlayerIds[i]}" autocomplete="off">`
          );
        }
        
        for (var i = 0; i < selectedPlayerNames.length; i++) {
          $('#create_btn').append(
            `<input type="hidden" name="p_names[]" value="${selectedPlayerNames[i]}" autocomplete="off">`
          );
        }

        // リーグプレイヤー編集モードの場合はparamsを追加
        if (leaguePlayerEdit) {
          $('#create_btn').append(
            `<input type="hidden" name="league_player" value="edit" autocomplete="off">`
          );
        }

      };
    }
    
    function updateCreateButtonActivation() {
      if (leaguePlayerEdit) {
        // リーグプレイヤー編集モードの場合は、プレイヤー人数がplay_type以上、上限人数以下であれば#create_btnをアクティブにする
        if (minPlayers <= $('.selected_players-item').length && $('.selected_players-item').length <= maxPlayers) {
          $('#create_btn').prop('disabled', false).removeClass('inactive');
        } else {
          $('#create_btn').prop('disabled', true).addClass('inactive');
        }
      } else {
        // プレイヤー人数が上限に達している場合に#create_btnをアクティブにするか非アクティブにする
        if ($('.selected_players-item').length === maxPlayers) {
          $('#create_btn').prop('disabled', false).removeClass('inactive');
        } else {
          $('#create_btn').prop('disabled', true).addClass('inactive');
        }
      }
    }
  
    // 選択済みプレイヤー数を更新する
    function update_selected_counter() {
      let selectedPlayerCount = $('.selected_players-item').length;
      $('.player-selected-count').text(selectedPlayerCount);
    }

    // URLパラメーター(play_type)を取得する
    function getUrlParameterPlayType() {
      let params = new URLSearchParams(window.location.search);
      return params.get(`play_type`);
    }
  }
});
