$(document).on('turbolinks:load', function () {

  // リーグ詳細の明細全体をリンクにする
  $('tr[data-href]').on('click', function() {
    window.location = $(this).data('href');
  });

});

// <leagueフォーム> ルール登録ボタンをクリックしたら入力されているリーグ名をセッションへ格納
$(document).on('click', '#rule_link', function() {
  var leagueName = document.querySelector('#league_name').value;
  window.sessionStorage.setItem(['league_name'],[leagueName]);
});

// <leagueフォーム> ページ読み込み時にセッションに格納されているリーグ名を表示
document.addEventListener('DOMContentLoaded', function() {
  var leagueName = window.sessionStorage.getItem('league_name');
  if (leagueName) {
    document.querySelector('#league_name').value = leagueName;
    // セッションに格納されているリーグ名を削除
    window.sessionStorage.removeItem('league_name');
  }
});

// <leagueフォーム> play_typeが四麻のときプレイヤー数は4人以上である場合のみ登録・更新ボタンを有効化
// 現在のパスがleagues/:id/edit の場合のみ実行する
$(document).on('turbolinks:load', function () {
  currentPath = window.location.pathname;
  if (currentPath.match(/\/leagues\/\d+\/edit/)) {
    const playTypeSelect = document.querySelector('#league_play_type');
    const submitButton = document.querySelector('#league_create_btn');

    // プレイヤー数が4人以上の場合のみ登録・更新ボタンを有効化
    function toggleSubmitButton() {
      const playType = parseInt(playTypeSelect.value, 10);
      const playerNum = document.querySelectorAll('#league-form .chip').length;

      if (playType === 4 && playerNum < 4) {
        submitButton.disabled = true;
        submitButton.classList.add('inactive');// ボタンを薄い色にする
      } else {
        submitButton.disabled = false;
        submitButton.classList.remove('inactive');// ボタンの色を元に戻す
      }
    }

    playTypeSelect.addEventListener('change', toggleSubmitButton);

    // 初期状態のチェック
    toggleSubmitButton();
  }
});

// <league記録プレイヤー選択フォーム>
window.addEventListener('load', function() {
  var currentPath = window.location.pathname;
  var match = currentPath.match(/\/leagues\/(\d+)\/select_players\/new/);

  if (!match) return;

  var checkboxes = document.querySelectorAll('.league-player-card input[type="checkbox"]');
  var playType = parseInt(document.querySelector('.play-type-hidden').textContent);
  var selectBtn = $('#league_players_select_btn');

  // プレイヤー決定ボタンの状態を更新
  function updateButtonState(checkedCount) {
    if (checkedCount === playType) {
      selectBtn.prop('disabled', false).removeClass('inactive');
    } else {
      selectBtn.prop('disabled', true).addClass('inactive');
    }
    $('.player-selected-count').text(checkedCount);
  }

  var loadCheckedCount = Array.from(checkboxes).filter(i => i.checked).length; // ページ読み込み時のチェック数
  updateButtonState(loadCheckedCount);

  checkboxes.forEach(function(checkbox) {
    checkbox.addEventListener('change', function() {
      var checkedCount = Array.from(checkboxes).filter(i => i.checked).length; // チェック数
      if (checkedCount > playType) {
        alert("選択できるのは" + playType + "人までです。");
        this.checked = false;
        checkedCount = checkedCount - 1;
      }
      updateButtonState(checkedCount);
    });
  });
});

// 成績一覧テーブルの固定列の幅を調整
if (window.location.pathname.includes('/leagues')) {
  window.addEventListener('resize', updateStickyColumns);
  window.addEventListener('DOMContentLoaded', updateStickyColumns);

  function updateStickyColumns() {
    const element = document.querySelector('.fixed-01');
    const firstColumnWidth = element ? element.offsetWidth : 0;
    const secondColumns = document.querySelectorAll('.fixed-02');

    secondColumns.forEach(column => {
      column.style.left = `${firstColumnWidth}px`;
    });
  }
}

