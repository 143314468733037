$(document).on('turbolinks:load', function () {
  function toggleMenu() {
    $(".open-btn").toggleClass('active');
    $("#g-nav").toggleClass('panel-active');
    $("body").toggleClass('no-scroll');
    $("#hamburger-menu-bg").toggleClass('active');
  }

  $(".open-btn").on('click', toggleMenu);
  $("#g-nav a").on('click', toggleMenu);
  $("#hamburger-menu-bg").on('click', toggleMenu);
});