// 親オプションの選択状態によって対局成績登録リンクのURLにクエリパラメータ(parent_option)を追加する
if (/\/matches\/\d+$/.test(window.location.pathname)) {
  document.addEventListener('DOMContentLoaded', function() {
    const selectElement = document.getElementById('parent-option-select');
    const matchLink = document.getElementById('new-match-link');

    selectElement.addEventListener('change', function() {
      const selectedValue = selectElement.value;
      let url = new URL(matchLink.href);

      if (selectedValue) {
        url.searchParams.set('parent_option', selectedValue);
      } else {
        url.searchParams.delete('parent_option');
      }

      matchLink.href = url.toString();
    });
  });
}
